<script>
export default {
  name: 'GlueSmallSpecial',
  props: {
    devise: { type: Object },
    special: {
      type: Object,
      required: true,
    },
    track: {
      type: String,
      default: null,
    },
    imageOnTop: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'glue',
    },
    location: {
      type: String,
      default: 'inPage',
    },
    showBookNow: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    titleHeight() {
      if (this.$voix.breakpoint.value !== 'default' && this.$voix.breakpoint.value !== 'sm')
        return { height: '130px' }

      return {}
    },
    copyHeight() {
      if (this.$voix.breakpoint.value !== 'default' && this.$voix.breakpoint.value !== 'sm')
        return { height: '360px' }

      return {}
    },
    descriptionHeight() {
      if (this.$voix.breakpoint.value !== 'default' && this.$voix.breakpoint.value !== 'sm')
        return { height: '42px' }

      return {}
    },
    learnMoreLink() {
      if (this.special.page?.permalink)
        return this.special.page.permalink

      return null
    },
  },
  methods: {
    goToSpecial() {
      location.href = this.special.page?.permalink
    },

    pushToDataLayer() {
      if (window.dataLayer)
        window.dataLayer.push({ offerName: this.special.title })
    },
  },
}
</script>

<template>
  <div
    v-if="special.id"
    class="flex justify-between"
    :class="{ 'flex-col': !imageOnTop, 'flex-col-reverse': imageOnTop }"
  >
    <div class="bg-white pt-4 px-8 pb-8 w-full flex-grow" :style="copyHeight">
      <div class="mb-12">
        <div v-if="location === 'slideOut'" :style="titleHeight">
          <div
            class="font-light text-red-500 italic font-serif mb-1 text-xl tracking-wider"
          >
            <span>{{ special.title }}</span>
          </div>
          <div
            v-if="special.featured"
            class="uppercase font-sans font-light leading-tight text-2xl mb-4 tracking-widest"
            :class="{
              'text-gray-800 ': mode === 'glue',
              'text-glueblue-600': mode === 'summer',
            }"
          >
            Exclusive Cyber Sale Offer
          </div>
        </div>
        <div v-else :style="titleHeight">
          <div
            class="font-light text-red-500 italic font-serif mb-1 text-xl tracking-wider"
          >
            <span v-if="special.featured">Exclusive Cyber Sale Offer</span>
            <span v-else>&nbsp;<!-- spacer --></span>
          </div>
          <div
            class="uppercase font-sans font-light leading-tight text-2xl mb-4 tracking-widest"
            :class="{
              'text-gray-800 ': mode === 'glue',
              'text-glueblue-600': mode === 'summer',
            }"
          >
            {{ special.title }}
          </div>
        </div>
        <div
          class="font-sans2 text-15 mb-6"
          :class="{
            'text-zinc-600 ': mode === 'glue',
            'text-glueblue-600': mode === 'summer',
          }"
          :style="descriptionHeight"
          v-html="special.short_description"
        />
      </div>
      <div class="pt-6 flex justify-start items-center">
        <BookingBookNow
          v-if="special.show_book_now_btn && showBookNow"
          :dropdown="false"
          :location="`Special - ${special.title}`"
          :special="special.id"
          :track="track"
          disableable
          @click.native="pushToDataLayer"
        >
          <template #default="slotProps">
            <button
              class="py-3 px-6 mr-6 leading-snug uppercase focus:outline-none font-sans text-xs"
              :class="{
                'bg-glueblue-700 text-white': slotProps.active,
                ' btn-ghost border-gray-500 btn-glue-active': !slotProps.active,
              }"
            >
              Book Now
            </button>
          </template>
        </BookingBookNow>
        <a
          v-for="(link, index) in special.links"
          :key="index"
          :href="link.href"
          class="mt-2 special-learnmore uppercase text-xs font-sans text-glueblue-400 border-b border-glueblue-400 hover-draw-border pb-1"
        >
          {{ link.text }}
        </a>
      </div>
    </div>
    <div
      v-if="special?.small_image"
      class="bg-cover bg-center"
      style="height: 225px"
      :style="{
        backgroundImage: `url(${special.small_image})`,
      }"
    />
    <div
      v-if="!special?.small_image && special?.main_image"
      class="bg-cover bg-center"
      style="height: 225px"
      :style="{
        backgroundImage: `url(${special.main_image})`,
      }"
    />
  </div>
</template>
